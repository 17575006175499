*{
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

body{
  background-color: #FDFAF3;
  padding: 50px 0;
}
button{
  cursor: pointer;
}
p, ul{
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  list-style: none;
  color: #404040;
}
a{
  color:#404040;
}
h2{
  font-size: 28px;
  font-weight: 600;
  color: #282828;
}
h2 img{
  position: relative;
  top: 2px;
  right: -2px;
}
h3{
  font-size: 20px;
  color: #282828;
}
.sp-content{
  max-width: 672px;
  margin:auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.sp-flex{display: flex}
.sp-flex-row{flex-direction: row;}
.sp-flex-column{flex-direction: column;}
.sp-text-para{
  /* font-family: Avenir Next; */
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}
.sp-semi-bold{font-weight: 600;}
.sp-underline{text-decoration: underline;}

/* Elements */

.sp-navbar{
  justify-content: space-between;
}
.hero-section{
  margin-top: 80px;
}
.sp-navbar ul{
  display: flex;
  list-style: none;
}
.sp-navbar ul li{
  margin-left: 30px;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  line-height: 44px;
  color: #282828;
}
.sp-navbar ul li.active{
  font-weight: 600;
}
.sp-navbar ul li::before{
  cursor: default;
  content: '';
  height: 15px;
  width: 15px;
  /* background-color: red; */
  background: url('./components/img/icon_navstar.svg') no-repeat center center;
  position: absolute;
  top: 14px;
  left: -22px;
}
.sp-navbar ul li:first-child:before{
  content: none;
}
.sp-navbar ul li a{
  text-decoration: none;
  display: block;
  height: 100%;
}
.sp-separator{
  margin-top: 0;
  margin-bottom: 15px;
}
.sp-separator img{ 
  width: 100%;
}
.sp-project-section{
  margin-bottom: 15px;
  border-radius: 20px;
  transition: 0.4s;
  cursor: url('./components/img/sp_cursor.svg'), auto;
}
.sp-project-section:hover{
  cursor: pointer;
  background-color: #ececec;
  cursor: url('./components/img/sp_cursor.svg'), auto;
}
/* .project-thumbnail{
  margin-bottom: 24px;
} */
.sp-tag-name{
  padding: 0;
  margin-right: 15px;
}
.sp-tag-name span{
  display: flex;
  color: #404040;
}
.sp-tag-name span img{
  margin-right: 5px;
}
.sp-footnote{
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #30303070;
  margin-top: 40px;
}

.selected-work{
  color: #404040;
  font-weight: 400;
  font-size: 16px;
  padding: 0 15px 5px;
  position: relative;
}
.email-availability button{
  background: #FDFAF3;
  padding: 5px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 5px;
  position: relative;
}
.email-availability button span{
  position: relative;
}
.email-availability button span img{
  position: relative;
  top: 3px;
  margin-right: 3px;;
}
.social-icons{
  display: flex;
  flex-direction: row;
  /* margin-top: -5px; */
}
.social-icons li{
  margin-right: 15px;
  opacity: 50%;
}
.social-icons li:hover{
  opacity: 100%;
}
span.sp-available{
  position: absolute;
  top: 0px;
  right: 20px;
  font-size: 14px;
}
span.sp-available::before{
  content:'';
  display: block;
  height: 15px;
  width: 15px;
  position: absolute;
  left: -20px;
  top: 3px;
  /* background-color: rgb(172, 96, 96); */
  background: url('./components/img/sp_available.gif') no-repeat center center;
  background-size: 45px;
}